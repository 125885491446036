import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Center, Link, ListItem, OrderedList, Stack } from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { Link as WouterLink } from 'wouter'
import { Container } from '../../common/components/Container'
import { isJa, useLang } from '../../common/i18n/useLang'
import { Title } from '../components/Title'

const jaText = (
  <div>
    大阪大学アドミッション支援デスク（AAD）は、個人情報保護の重要性を認識し、AAD申請システムの運用に当たっては法令を遵守し、
    <Link
      href="https://www.osaka-u.ac.jp/kitei/reiki_honbun/u035RG00001025.html"
      isExternal
      color="teal.500"
    >
      国立大学法人大阪大学の保有する個人情報の管理に関する規程
      <ExternalLinkIcon mx="2px" />
    </Link>
    及び以下の方針に基づき個人情報の適切な管理に努めます。
  </div>
)

const enText = (
  <div>
    In recognition of the growing importance of privacy protection, The University of Osaka Admissions
    Assistance Desk (AAD) strives to comply with all applicable laws and to protect personal
    information on the AAD application system in accordance with its
    <Link
      href="https://www.osaka-u.ac.jp/kitei/reiki_honbun/u035RG00001025.html"
      isExternal
      color="teal.500"
      pl={1}
    >
      Regulations for Personal Information Disclosure held by The University of Osaka
      <ExternalLinkIcon mx="2px" />
    </Link>
    and the privacy policy described below.
  </div>
)

const Privacy: VFC = () => {
  const { t } = useLang()
  useTitle(t('AAD 個人情報の取り扱いについて'))
  return (
    <Container innerWidth={'800px'}>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Title text={t('個人情報の取り扱いについて')} />
          {isJa() ? jaText : enText}
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の取得・保有について')} />
          <OrderedList pl={'1.5em'}>
            <ListItem>{t('本学では、偽りその他不正な手段により個人情報を取得しません。')}</ListItem>
            <ListItem>
              {t(
                '本学では、業務の遂行に必要な場合に限り個人情報を保有し、かつ、その利用目的を特定します｡'
              )}
            </ListItem>
            <ListItem>
              {t(
                '本学では、特定された利用目的の達成に必要な範囲を超えて、個人情報を保有しません。'
              )}
            </ListItem>
            <ListItem>
              {t(
                '本学では、特定された利用目的を変更する場合、変更前の利用目的と相当の関連性を有すると認められる範囲を超えて行いません｡'
              )}
            </ListItem>
          </OrderedList>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の利用について')} />
          <Box>
            {t(
              '本学では、法令に基づく場合を除き、事前に本人の同意を得ることなく、利用目的以外の目的のために個人情報を利用し、又は提供しません。'
            )}
          </Box>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の管理について')} />
          <Box>
            {t(
              '本学では、個人情報の漏えい、紛失又は改ざんの防止その他の個人情報の適切な管理のために必要な対策を講じます。'
            )}
          </Box>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の開示等について')} />
          <Box>
            {t(
              '本学では、個人情報について開示、訂正、利用停止の請求があった場合、本学規程の定めるところにより、速やかに対応します。'
            )}
          </Box>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の廃棄')} />
          <Box>
            {t('本学では、不要になった個人情報については、安全かつ適切な方法により廃棄します。')}
          </Box>
        </Stack>

        <Center>
          <Link as={WouterLink} to="/" isExternal color="teal.500">
            {t('トップページへ戻る')}
          </Link>
        </Center>
      </Stack>
    </Container>
  )
}

export default Privacy
